<template>
  <div class="userConfig">
    <div class="facilityBox">
      <el-form class="manageForm" :model="fromData" :inline="true" label-width="70px">
        <el-form-item label="账号" prop="userName">
          <el-input class="fromInp" v-model="fromData.Name" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="Name">
          <el-input class="fromInp" v-model="fromData.ChineseName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="Name">
          <el-input class="fromInp" v-model="fromData.identityCard" placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="searchFrom()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="userlist" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
        <el-table-column align="center" type="index" fixed label="序号" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="ID" fixed label="用户ID" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Name" fixed label="账号" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="ChineseName" label="姓名" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="IdentityCard" label="身份证" width="160"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CreateTime" label="创建时间" width="160"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="LastLoginTime" width="160" label="最后一次登录时间"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Title" label="职务" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="RoleName" label="角色名" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="RoleStatus" label="角色状态" width="120"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="RoleRemark" label="角色备注" width="120"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Address" label="地址" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="IdentityName" label="身份类型" width="120"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AscriptionName" label="归属用户ID" width="120"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AssignCheckName" label="审核员" width="120"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AssignName" label="客服" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AgentName" label="代理ID" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AgentChannel" label="用户来源" width="100"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AgentType" label="用户来源" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="AgentBindTime" label="与代理商签订日期" width="160"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Remark" label="备注" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="是否删除" width="100" show-overflow-tooltip fixed="right">
          <template slot-scope="scope">
            <el-tag :type="scope.row.IsDelete ? 'success' : 'danger'">{{
              scope.row.IsDelete ? "已删除" : "未删除"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否启用" width="100" show-overflow-tooltip fixed="right">
          <template slot-scope="scope">
            <el-tag :type="scope.row.Enabled ? 'success' : 'danger'">{{
              scope.row.Enabled ? "已启用" : "未启用"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" @click="userConfig(scope.row)" icon="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background class="pagination" @current-change="configChange" :current-page.sync="configData.page"
        :page-size="configData.pagesize" layout="total, prev, pager, next, jumper" :total="configData.total">
      </el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog class="addDialog" width="604px" :visible.sync="isUser">
      <div slot="title" class="dialog-title">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑用户信息" name="first"></el-tab-pane>
          <el-tab-pane label="删除/启用" name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <el-form class="manageForm" :model="userInfo" :inline="true" label-width="70px" v-if="activeName == 'first'">
        <el-form-item label="姓名" prop="ChineseName">
          <el-input class="fromInp" v-model="userInfo.ChineseName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="Password">
          <el-input class="fromInp" type="password" v-model="userInfo.Password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="IdentityCard">
          <el-input class="fromInp" v-model="userInfo.IdentityCard" placeholder="请输入身份证"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="Address">
          <el-input class="fromInp" v-model="userInfo.Address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="Remark">
          <el-input :show-word-limit="true" maxlength="250" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
            :rows="2" placeholder="请输入备注" v-model="userInfo.Remark">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="是否删除" prop="IsDelete" style="margin-bottom: 0px;">
          <el-switch v-model="userInfo.IsDelete" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否启用" prop="Enabled" style="margin-bottom: 0px;">
          <el-switch v-model="userInfo.Enabled" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item> -->
        <el-form-item style="display: block;width: 100%;text-align: right;margin-top: 10px;">
          <el-button @click="isUser = false">取 消</el-button>
          <el-button type="primary" @click="upDateConfig()">确 定</el-button>
        </el-form-item>
      </el-form>
      <el-form class="manageForm" :model="userInfo" :inline="true" label-width="70px" v-else>
        <el-form-item label="是否删除" prop="IsDelete" style="margin-bottom: 0px;">
          <el-switch v-model="userInfo.IsDelete" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否启用" prop="Enabled" style="margin-bottom: 0px;">
          <el-switch v-model="userInfo.Enabled" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item style="display: block;width: 100%;text-align: right;margin-bottom: 0px;">
          <el-button @click="isUser = false">取 消</el-button>
          <el-button type="primary" @click="upDateConfig()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { GetUsersList, OperateUsers,UserEnabled } from "@/api/configFlie/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      activeName: "first",
      //分页控件相关参数
      configData: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0,
      },
      // 请求数据参数
      fromData: {
        userId: 0,
        Name: "",
        ChineseName: "",
        identityCard: "",
      },
      // 用户列表
      userlist: [],
      // 表格loading
      loading: true,
      // 单条用户信息
      userInfo: {},
      // 是否显示编辑弹窗
      isUser: false,
    };
  },
  created() {
    // 获取用户列表
    this.GetUserslist();
  },
  methods: {
    upDateConfig() {
      let data = {}
      let ajaxFun = ''
      if (this.activeName == "first") {
        data = {
          id: this.userInfo.ID,
          password: md5(String(this.userInfo.Password)),
          chineseName: this.userInfo.ChineseName,
          address: this.userInfo.Address,
          remark: this.userInfo.Remark,
          identityCard: this.userInfo.IdentityCard,
          enabled: this.userInfo.Enabled,
          isDelete: this.userInfo.IsDelete ? 1 : 0,
        };
        ajaxFun = OperateUsers
      } else {
        data = {
          userId: this.userInfo.ID,
          enabled: this.userInfo.Enabled,
          isDelete: this.userInfo.IsDelete ? 1 : 0,
        };
        ajaxFun = UserEnabled
      }

      ajaxFun(data).then((res) => {
        this.$message({
          message: "编辑成功",
          type: "success",
        });
        // 获取用户列表
        this.GetUserslist();
        this.isUser = false;
      });
    },
    // 编辑用户
    userConfig(item) {
      item.IsDelete = item.IsDelete == 1 ? true : false;
      console.log(item);
      this.userInfo = item;
      this.isUser = true;
      this.activeName = "first"
    },
    // 清空搜索
    resetForm() {
      this.configData.page = 1;
      this.fromData = {
        userId: 0,
        Name: "",
        ChineseName: "",
        identityCard: "",
      };
      // 获取用户列表
      this.GetUserslist();
    },
    // 分页请求
    configChange(e) {
      this.configData.page = e;
      // 获取用户列表
      this.GetUserslist();
    },
    // 搜索
    searchFrom() {
      this.loading = true;
      this.configData.page = 1;
      // 获取用户列表
      this.GetUserslist();
    },
    // 获取用户列表
    GetUserslist() {
      let data = {
        userId: this.fromData.userId,
        Name: this.fromData.Name,
        ChineseName: this.fromData.ChineseName,
        identityCard: this.fromData.identityCard,
        pageSize: this.configData.pagesize,
        pageIndex: this.configData.page,
      };
      GetUsersList(data)
        .then((res) => {
          this.userlist = res.data.DataList;
          this.configData.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.userConfig {
  .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  .el-textarea {
    width: 484px;
    height: 115px;

    textarea {
      height: 115px !important;
      padding: 10px 20px 10px 15px;
    }

    .el-input__count {
      bottom: -40px;
      right: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
// .userConfig{

// }
</style>
